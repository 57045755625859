import { LinkedInLogoIcon } from '@radix-ui/react-icons';

import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './LinkedinLink.css';

export function LinkedinLink() {
  return (
    <Box asChild>
      <a
        href="https://www.linkedin.com/in/zenobiusjiricek/?ref=zenobi.us"
        title="Zenobius Jiricek on LinkedIn"
        className={Styles.link}
        rel="noopener noreferrer"
        target="_blank"
      >
        <LinkedInLogoIcon
          width={32}
          height={32}
        />
      </a>
    </Box>
  );
}
