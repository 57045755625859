import { EnvelopeClosedIcon } from '@radix-ui/react-icons';

import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './EmailLink.css';

export function EmailLink() {
  return (
    <Box
      align="center"
      justify="center"
      asChild
    >
      <a
        href="/contact"
        title="Send me a direct message"
        className={Styles.link}
        rel="noopener noreferrer"
        target="_blank"
      >
        <EnvelopeClosedIcon
          width={32}
          height={32}
        />
      </a>
    </Box>
  );
}
