import classnames from 'classnames';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import type { BoxProps } from '@zenobius/ui-web-components-box';
import { Box } from '@zenobius/ui-web-components-box';

import { useClientSideFaviconColourStorage } from './useClientSideFaviconColourStorage';
import * as Styles from './Avatar.css';
import { Logo } from './Logo';

type AvatarProps = BoxProps & { size?: number };

export function Avatar({ size, className, ...props }: AvatarProps) {
  const colours = useClientSideFaviconColourStorage();
  return (
    <Box
      className={classnames(className, Styles.logo)}
      {...props}
      asChild
    >
      <Logo
        className={Styles.svg}
        width={size}
        height={size}
        {...colours.value}
      />
    </Box>
  );
}

export function HomeAvatar({ ...props }: AvatarProps) {
  return (
    <Box
      position="relative"
      className={Styles.homeAvatar}
      style={assignInlineVars({
        [Styles.HomeAvatarSize]: `${props.size || 32}px`,
      })}
    >
      <Avatar
        {...props}
        position="absolute"
      />
    </Box>
  );
}
