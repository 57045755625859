import { GitHubLogoIcon } from '@radix-ui/react-icons';

import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './GithubLink.css';

export function GithubLink() {
  return (
    <Box asChild>
      <a
        href="https://github.com/airtonix?ref=zenobi.us"
        title="Zenobius Jiricek on GitHub"
        rel="noopener noreferrer"
        target="_blank"
        className={Styles.link}
      >
        <GitHubLogoIcon
          width={32}
          height={32}
        />
      </a>
    </Box>
  );
}
