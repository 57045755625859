import { InstagramLogoIcon } from '@radix-ui/react-icons';

import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './GithubLink.css';

export function InstagramLink() {
  return (
    <Box asChild>
      <a
        href="https://instagram.com/zenobi.us?ref=zenobi.us"
        title="Zenobius Jiricek on Instagram"
        rel="noopener noreferrer"
        target="_blank"
        className={Styles.link}
      >
        <InstagramLogoIcon
          width={32}
          height={32}
        />
      </a>
    </Box>
  );
}
