import { assignInlineVars } from '@vanilla-extract/dynamic';
import classnames from 'classnames';

import { Tokens } from '@zenobius/ui-web-uikit/tokens';
import { LinkList } from '@zenobius/ui-web-components-linklist';
import { Box } from '@zenobius/ui-web-components-box';
import { Avatar } from '@zenobius/ui-favicons';
import { Link } from '@zenobius/ui-web-components-link';

import { GithubLink } from './GithubLink';
import { Block } from '../Block/Block';
import { LinkedinLink } from './LinkedinLink';
import * as Styles from './GlobalNav.css';
import { useCurrentPath } from './useNavigation';
import { EmailLink } from './EmailLink';
import { InstagramLink } from './InstagramLink';

export function GlobalNav({
  className,
  useLogo,
  currentPath,
}: {
  className?: string;
  currentPath?: string;
  useLogo?: boolean;
}) {
  const { isAncestor } = useCurrentPath(currentPath);

  return (
    <Block
      className={classnames(
        'globalnav',
        'print--hide',
        Styles.block,
        className,
      )}
    >
      <Box
        justify="space-between"
        width="Big"
        marginRight="Small"
        style={assignInlineVars({
          [Tokens.spacing.Big]: '100%',
        })}
      >
        <Box
          gap="Normal"
          alignItems="center"
        >
          {useLogo && (
            <Link href="/">
              <Avatar size={32} />
            </Link>
          )}
          <LinkList
            direction="row"
            gap="Normal"
            align="center"
            className={classnames('globalnav__links', Styles.links)}
          >
            <Link
              href="/b"
              className={classnames(isAncestor('/b') && Styles.ancestorLink)}
            >
              Posts
            </Link>
          </LinkList>
        </Box>
        <LinkList
          direction="row"
          gap="Normal"
          align="center"
          className={classnames('globalnav__external', Styles.external)}
        >
          <GithubLink />
          <LinkedinLink />
          <InstagramLink />
          <EmailLink />
        </LinkList>
      </Box>
    </Block>
  );
}
